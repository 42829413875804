import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const FounderImage = ({ imageKey, ...props }) => {
  const data = useStaticQuery(graphql`
    query {
      samon: file(
        sourceInstanceName: { eq: "images" }
        relativePath: { eq: "people/samon-cropped.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 300, maxHeight: 300, toFormat: JPG) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      raphassit: file(
        sourceInstanceName: { eq: "images" }
        relativePath: { eq: "people/raphassit-cropped.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 300, maxHeight: 300, toFormat: JPG) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return <Img fluid={data[`${imageKey}`].childImageSharp.fluid} {...props} />
}

export default FounderImage
