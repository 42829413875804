import React from "react"
import { Trans, useTranslation } from "react-i18next"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Container, Row, Col } from "reactstrap"

import FadedLine from "./faded_line"

import "./features.scss"

const Features = () => {
  const { t } = useTranslation()

  return (
    <section className="Features" id="features">
      <Container>
        <h2 className="Title">{t("features.title")}</h2>
        {/* Feature 1 */}
        <Row className="FeatureItem">
          <Col className="FeatureItemImageContainer" sm="12" md="6">
            <FeatureImage featureKey="feature1" className="FeatureImage" />
          </Col>
          <Col className="FeatureItemTextContainer" sm="12" md="6">
            <h3 className="FeatureTitle">{t("features.feature1.title")}</h3>
            {t("features.feature1.lines", {
              returnObjects: true,
            }).map((text, index) => (
              <p key={index}>
                <Trans defaults={text} components={{ bold: <strong /> }} />
              </p>
            ))}
          </Col>
        </Row>
        <FadedLine />
        {/* Feature 2 */}
        <Row className="FeatureItem Reverse">
          <Col className="FeatureItemImageContainer" sm="12" md="6">
            <FeatureImage featureKey="feature2" className="FeatureImage" />
          </Col>
          <Col className="FeatureItemTextContainer" sm="12" md="6">
            <h3 className="FeatureTitle">{t("features.feature2.title")}</h3>
            {t("features.feature2.lines", {
              returnObjects: true,
            }).map((text, index) => (
              <p key={index}>
                <Trans defaults={text} components={{ bold: <strong /> }} />
              </p>
            ))}
          </Col>
        </Row>
        <FadedLine />
        {/* Feature 3 */}
        <Row className="FeatureItem">
          <Col className="FeatureItemImageContainer">
            <FeatureImage featureKey="feature3" className="FeatureImage" />
          </Col>
          <Col className="FeatureItemTextContainer">
            <h3 className="FeatureTitle">{t("features.feature3.title")}</h3>
            {t("features.feature3.lines", {
              returnObjects: true,
            }).map((text, index) => (
              <p key={index}>
                <Trans defaults={text} components={{ bold: <strong /> }} />
              </p>
            ))}
          </Col>
        </Row>
        <FadedLine />
        {/* Feature 5 */}
        <Row className="FeatureItem Reverse">
          <Col className="FeatureItemImageContainer">
            <FeatureImage featureKey="feature5" className="FeatureImage" />
          </Col>
          <Col className="FeatureItemTextContainer">
            <h3 className="FeatureTitle">{t("features.feature5.title")}</h3>
            {t("features.feature5.lines", {
              returnObjects: true,
            }).map((text, index) => (
              <p key={index}>
                <Trans defaults={text} components={{ bold: <strong /> }} />
              </p>
            ))}
          </Col>
        </Row>
        <FadedLine />
        {/* Feature 4 */}
        <Row className="FeatureItem">
          <Col className="FeatureItemImageContainer">
            <FeatureImage featureKey="feature4" className="FeatureImage" />
          </Col>
          <Col className="FeatureItemTextContainer">
            <h3 className="FeatureTitle">{t("features.feature4.title")}</h3>
            {t("features.feature4.lines", {
              returnObjects: true,
            }).map((text, index) => (
              <p key={index}>
                <Trans defaults={text} components={{ bold: <strong /> }} />
              </p>
            ))}
          </Col>
        </Row>
        <FadedLine />
        {/* Feature 6 */}
        <Row className="FeatureItem Reverse">
          <Col className="FeatureItemImageContainer">
            <FeatureImage featureKey="feature6" className="FeatureImage" />
          </Col>
          <Col className="FeatureItemTextContainer">
            <h3 className="FeatureTitle">{t("features.feature6.title")}</h3>
            {t("features.feature6.lines", {
              returnObjects: true,
            }).map((text, index) => (
              <p key={index}>
                <Trans defaults={text} components={{ bold: <strong /> }} />
              </p>
            ))}
          </Col>
        </Row>
      </Container>
    </section>
  )
}

const FeatureImage = ({ featureKey, ...props }) => {
  const data = useStaticQuery(graphql`
    query {
      feature1Image: file(
        sourceInstanceName: { eq: "images" }
        relativePath: { eq: "features/feature1.png" }
      ) {
        childImageSharp {
          fluid(
            maxWidth: 350
            maxHeight: 350
            fit: CONTAIN
            background: "rgba(0,0,0,0)"
          ) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      feature2Image: file(
        sourceInstanceName: { eq: "images" }
        relativePath: { eq: "features/feature2.png" }
      ) {
        childImageSharp {
          fluid(
            maxWidth: 350
            maxHeight: 350
            fit: CONTAIN
            background: "rgba(0,0,0,0)"
          ) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      feature3Image: file(
        sourceInstanceName: { eq: "images" }
        relativePath: { eq: "features/feature3.png" }
      ) {
        childImageSharp {
          fluid(
            maxWidth: 350
            maxHeight: 350
            fit: CONTAIN
            background: "rgba(0,0,0,0)"
          ) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      feature4Image: file(
        sourceInstanceName: { eq: "images" }
        relativePath: { eq: "features/feature4.png" }
      ) {
        childImageSharp {
          fluid(
            maxWidth: 350
            maxHeight: 350
            fit: CONTAIN
            background: "rgba(0,0,0,0)"
          ) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      feature5Image: file(
        sourceInstanceName: { eq: "images" }
        relativePath: { eq: "features/feature5.png" }
      ) {
        childImageSharp {
          fluid(
            maxWidth: 350
            maxHeight: 350
            fit: CONTAIN
            background: "rgba(0,0,0,0)"
          ) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      feature6Image: file(
        sourceInstanceName: { eq: "images" }
        relativePath: { eq: "features/feature6.png" }
      ) {
        childImageSharp {
          fluid(
            maxWidth: 350
            maxHeight: 350
            fit: CONTAIN
            background: "rgba(0,0,0,0)"
          ) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Img fluid={data[`${featureKey}Image`].childImageSharp.fluid} {...props} />
  )
}

export default Features
