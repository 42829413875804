import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const PeopleImage = ({ imageKey, ...props }) => {
  const data = useStaticQuery(graphql`
    query {
      utt: file(
        sourceInstanceName: { eq: "images" }
        relativePath: { eq: "people/utt-cropped.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 150, maxHeight: 150, toFormat: JPG) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      jinda: file(
        sourceInstanceName: { eq: "images" }
        relativePath: { eq: "people/jinda-cropped.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 150, maxHeight: 150, toFormat: JPG) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      anutida: file(
        sourceInstanceName: { eq: "images" }
        relativePath: { eq: "people/anutida-cropped.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 150, maxHeight: 150, toFormat: JPG) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      lin: file(
        sourceInstanceName: { eq: "images" }
        relativePath: { eq: "people/lin-cropped.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 150, maxHeight: 150, toFormat: JPG) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      ken: file(
        sourceInstanceName: { eq: "images" }
        relativePath: { eq: "people/ken-cropped.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 150, maxHeight: 150, toFormat: JPG) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return <Img fluid={data[`${imageKey}`].childImageSharp.fluid} {...props} />
}

export default PeopleImage
