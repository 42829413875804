import React from "react"
import { Trans, useTranslation } from "react-i18next"
import { Container, Row, Col } from "reactstrap"

import FounderImage from "./founder_image"
import PeopleImage from "./people_image"
import FadedLine from "./faded_line"

import "./people.scss"

const People = () => {
  const { t } = useTranslation()

  return (
    <section className="People" id="people">
      <Container>
        <h2 className="Title">{t("people.title")}</h2>
        {t("people.introLines", {
          returnObjects: true,
        }).map((text, index) => (
          <p key={index} className="Intro">
            <Trans defaults={text} components={{ bold: <strong /> }} />
          </p>
        ))}
        {/* Founders */}
        <Row>
          <Col sm="12" md={{ size: 5, offset: 1 }}>
            <div className="FounderContainer">
              <div className="ImageContainer">
                <FounderImage imageKey="samon" className="FounderImage" />
              </div>
              <h3 className="text-center">{t("people.founderList.0.name")}</h3>
              <h5 className="text-center">
                {t("people.founderList.0.position")}
              </h5>
              <FadedLine />
              {t("people.founderList.0.descriptionList", {
                returnObjects: true,
              }).map((text, index) => (
                <p key={index}>
                  <Trans defaults={text} components={{ bold: <strong /> }} />
                </p>
              ))}
            </div>
          </Col>
          <Col sm="12" md={{ size: 5 }}>
            <div className="FounderContainer">
              <div className="ImageContainer">
                <FounderImage imageKey="raphassit" className="FounderImage" />
              </div>
              <h3 className="text-center">{t("people.founderList.1.name")}</h3>
              <h5 className="text-center">
                {t("people.founderList.1.position")}
              </h5>
              <FadedLine />
              {t("people.founderList.1.descriptionList", {
                returnObjects: true,
              }).map((text, index) => (
                <p key={index}>
                  <Trans defaults={text} components={{ bold: <strong /> }} />
                </p>
              ))}
            </div>
          </Col>
        </Row>

        {/* People */}
        <Row>
          <Col sm="12" md="4">
            <div className="PeopleContainer">
              <div className="ImageContainer">
                <PeopleImage imageKey="utt" className="PeopleImage" />
              </div>
              <h4 className="text-center">{t("people.peopleList.0.name")}</h4>
              <h5 className="text-center">
                {t("people.peopleList.0.position")}
              </h5>
              <FadedLine />
              {t("people.peopleList.0.descriptionList", {
                returnObjects: true,
              }).map((text, index) => (
                <p key={index}>
                  <Trans defaults={text} components={{ bold: <strong /> }} />
                </p>
              ))}
            </div>
          </Col>
          <Col sm="12" md="4">
            <div className="PeopleContainer">
              <div className="ImageContainer">
                <PeopleImage imageKey="jinda" className="PeopleImage" />
              </div>
              <h4 className="text-center">{t("people.peopleList.1.name")}</h4>
              <h5 className="text-center">
                {t("people.peopleList.1.position")}
              </h5>
              <FadedLine />
              {t("people.peopleList.1.descriptionList", {
                returnObjects: true,
              }).map((text, index) => (
                <p key={index}>
                  <Trans defaults={text} components={{ bold: <strong /> }} />
                </p>
              ))}
            </div>
          </Col>
          <Col sm="12" md="4">
            <div className="PeopleContainer">
              <div className="ImageContainer">
                <PeopleImage imageKey="anutida" className="PeopleImage" />
              </div>
              <h4 className="text-center">{t("people.peopleList.2.name")}</h4>
              <h5 className="text-center">
                {t("people.peopleList.2.position")}
              </h5>
              <FadedLine />
              {t("people.peopleList.2.descriptionList", {
                returnObjects: true,
              }).map((text, index) => (
                <p key={index}>
                  <Trans defaults={text} components={{ bold: <strong /> }} />
                </p>
              ))}
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm="12" md="4">
            <div className="PeopleContainer">
              <div className="ImageContainer">
                <PeopleImage imageKey="lin" className="PeopleImage" />
              </div>
              <h4 className="text-center">{t("people.peopleList.3.name")}</h4>
              <h5 className="text-center">
                {t("people.peopleList.3.position")}
              </h5>
              <FadedLine />
              {t("people.peopleList.3.descriptionList", {
                returnObjects: true,
              }).map((text, index) => (
                <p key={index}>
                  <Trans defaults={text} components={{ bold: <strong /> }} />
                </p>
              ))}
            </div>
          </Col>
          <Col sm="12" md="4">
            <div className="PeopleContainer">
              <div className="ImageContainer">
                <PeopleImage imageKey="ken" className="PeopleImage" />
              </div>
              <h4 className="text-center">{t("people.peopleList.4.name")}</h4>
              <h5 className="text-center">
                {t("people.peopleList.4.position")}
              </h5>
              <FadedLine />
              {t("people.peopleList.4.descriptionList", {
                returnObjects: true,
              }).map((text, index) => (
                <p key={index}>
                  <Trans defaults={text} components={{ bold: <strong /> }} />
                </p>
              ))}
            </div>
          </Col>
          <Col></Col>
        </Row>
      </Container>
    </section>
  )
}

export default People
