import React from "react"
import { useTranslation } from "react-i18next"
import { Container } from "reactstrap"

import "./vision.scss"

const Vision = () => {
  const { t } = useTranslation()

  return (
    <section className="Vision" id="vision">
      <Container>
        <h2 className="Title">{t("vision.title")}</h2>
        <ul className="List">
          <li>{t("vision.list.0")}</li>
          <li>{t("vision.list.1")}</li>
          <li>{t("vision.list.2")}</li>
        </ul>
      </Container>
    </section>
  )
}

export default Vision
