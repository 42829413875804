import React from "react"
import { useTranslation } from "react-i18next"
import { Container, Row, Col } from "reactstrap"

import "./introduction.scss"

const Introduction = () => {
  const { t } = useTranslation()

  return (
    <section className="Introduction">
      <Container>
        <Row>
          <Col sm="12" md="6">
            <div className="TitleContainer">
              <h2 className="Title">{t("introduction.title")}</h2>
            </div>
          </Col>
          <Col sm="12" md="6">
            <p className="Text">{t("introduction.line1")}</p>
            <p className="Text">{t("introduction.line2")}</p>
            <p className="Text">{t("introduction.line3")}</p>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Introduction
