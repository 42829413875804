import React from "react"
import { Trans, useTranslation } from "react-i18next"

import AppLogoImage from "../components/app_logo_image"
import SEO from "../components/seo"

import Introduction from "../components/introduction"
import Features from "../components/features"
import Vision from "../components/vision"
import People from "../components/people"

import { getTranslation } from "../translation";

import "./index.scss"
import AppStoreBadgeSVG from "../images/app_store_badge.svg"
import GooglePlayBadgeImage from "../images/google-play-badge.png"

const IndexPage = ({ pageContext: { locale } }) => {
  const { t } = useTranslation()

  return (
    <>
      <SEO
        title={getTranslation(locale).mainPageTitle}
        lang={locale}
        disableTitleTemplate={true}
      />
      <section className="AppIntroSection" id="download">
        <div className="AppLogo">
          <AppLogoImage className="AppLogoImage" />
        </div>
        <h1 className="Name">JADOH Learning</h1>
        <h2 className="Slogan">{t("slogan")}</h2>
        <p className="TryFree">
          <Trans i18nKey="downloadFree" components={{ bold: <strong /> }} />
        </p>
        <div className="store-badge-group">
          <div className="store-badge">
            <a href="https://play.google.com/store/apps/details?id=com.jadoh.jadoh&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
              <img
                className="google-play-badge"
                alt="Get it on Google Play"
                src={GooglePlayBadgeImage}
              />
            </a>
          </div>
          <div className="store-badge">
            <a href="https://itunes.apple.com/us/app/jadoh/id1249964489?mt=8">
              <img
                className="apple-app-store-badge"
                alt="Download on the App Store"
                src={AppStoreBadgeSVG}
              />
            </a>
          </div>
        </div>
      </section>
      <div className="separator1">
        <div className="slope"></div>
      </div>
      <Introduction />
      <div className="separator2">
        <div className="slope"></div>
      </div>
      <Features />
      <div className="separator3">
        <div className="slope"></div>
      </div>
      <Vision />
      <div className="separator4">
        <div className="slope"></div>
      </div>
      <People />
    </>
  )
}

export default IndexPage
